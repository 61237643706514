
// 
import "./components/_scale";
import "./components/_toggleClass";
import "./components/_lightbox";
// import "./components/_preregister";
import "./components/_loadAjax";
import "./components/_loadRank";
// import "./components/_tab";
import "./components/_music";
import initIcons from "./components/_svg-icons";

$(function(){

    initIcons();

    $(".hamburger").toggleClass({
        toggle: [
            {
                el: $("nav"),
                className: 'active'
            },
            {
                el: $(".hamburger"),
                className: 'is-active'
            }
        ]
    });

    $(".sidebar__toggle").toggleClass({
        toggle: [
            {
                el: $(".sidebar"),
                className: 'active'
            }
        ]
    })

    // 
    
    if ($("[data-lightbox]").length > 0) {
        $("[data-lightbox]").each(function(){
            $(this).lightBox({
                objLightBox: $(this).attr('href'),
                type: $(this).data('lightbox-type')
            });
        }); 
    }

    // Use this for multiple-frame "contain" width scale mode


    $(".wrapperScale").each(function(){
        $(this).scaleWeb();
    });

    $("input").on("input", function(){
        if ($(this).val().length > 0) {
            $(this).next("label").css({
                display: 'none'
            });
        } else {
            $(this).next("label").css({
                display: 'flex'
            });
        }

    });

    $(".outer").css({
        opacity: 1
    });

    // preregister

    
    $("[data-loadajax-core]").each(function(){
        $(this).loadAjax({
            language: {
                'loading': 'Loading...',
                'error': 'Loading error!',
                'noresult': 'There is no result!'
            }
        });
    });
    
    $("[data-loadrank-core]").each(function(){
        $(this).loadRank({
            language: {
                'loading': 'Loading...',
                'error': 'Loading error!',
                'noresult': 'There is no result!'
            }
        });
    });
    
    // music

    $("#backgroundMusic").music({
        toggleEl: '#buttonMusic',
        toggleClass: 'active'
    });
});