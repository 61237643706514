

// LAZYLOAD
import 'lazysizes';

 
// CAROUSEL
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import SwiperAnimation from '@cycjimmy/swiper-animation';

$(function(){

    let swiperControl = {};

    $(".swiper-container-listEvents").each(function(){
        let swiperSelector = $(this).data("swiper-selector");
        let swiperSettings = new Swiper(swiperSelector, {
            centeredSlides: true,
            slidesPerView: 'auto',
            loop: true,
            // loopedSlides: 3,
            // speed: 200,
            spaceBetween: 0,
            // effect: 'fade', 
            lazy: {
                loadPrevNext: true,
            },
            slideToClickedSlide: true,
            watchSlidesVisibility: true,
            navigation: {
                nextEl: '.swiper-button-next-' + swiperSelector.substr(1), 
                prevEl: '.swiper-button-prev-' + swiperSelector.substr(1)
            },
            pagination: {
                el: '.swiper-pagination-' + swiperSelector.substr(1),
                clickable: true
            }
        })
        swiperControl[swiperSelector.substr(1)] = swiperSettings;
    });

    $(".swiper-container-listChars").each(function(){
        let swiperSelector = $(this).data("swiper-selector");
        const swiperAnimation = new SwiperAnimation();
        let swiperSettings = new Swiper(swiperSelector, {
            centeredSlides: true,
            slidesPerView: 'auto',
            loop: true,
            // loopedSlides: 3,
            // speed: 200,
            spaceBetween: 0,
            // effect: 'fade', 
            lazy: {
                loadPrevNext: true,
            },
            on: {
                init: function () {
                    swiperAnimation.init(this).animate();
                },
                slideChange: function () {
                    swiperAnimation.init(this).animate();
                }
            },
            slideToClickedSlide: true,
            watchSlidesVisibility: true,
            navigation: {
                nextEl: '.swiper-button-next-' + swiperSelector.substr(1), 
                prevEl: '.swiper-button-prev-' + swiperSelector.substr(1)
            },
            pagination: {
                el: '.swiper-pagination-' + swiperSelector.substr(1),
                clickable: true,
                renderBullet: function(index, className) {



                    const getLabel = () => {
                        return $(swiperSelector + "> .swiper-wrapper").children(".swiper-slide").eq(index).data("label");
                    }   

                    const getThumbnail = (label, status) => {
                        let baseURL = 'http://img.zing.vn/products/pwth/skin-2020-webpack/dist/assets/images/char-mb-page/';
                        let fileExt = '.png';


                        return {
                            normal: baseURL + label + fileExt,
                            hover: baseURL + label + '-hov' + fileExt
                        }
                    }

                    return `<div class="${className}">
                        <span class="thumbnail">
                            <img class="thumbnail__normal" src="${getThumbnail(getLabel()).normal}">
                            <img class="thumbnail__hover" src="${getThumbnail(getLabel()).hover}">
                        </span>
                        <span class="label">${getLabel()}</span>
                    </div>`
                }
            }
        })
        swiperControl[swiperSelector.substr(1)] = swiperSettings;
    });

    $(".swiper-container-listGallery").each(function(){
        let swiperSelector = $(this).data("swiper-selector");
        const swiperAnimation = new SwiperAnimation();
        let swiperSettings = new Swiper(swiperSelector, {
            centeredSlides: true,
            slidesPerView: 'auto',
            allowTouchMove: false,
            loop: true,
            spaceBetween: 0,
            effect: 'fade',
            lazy: {
                loadPrevNext: true,
            },
            on: {
                init: function () {
                    swiperAnimation.init(this).animate();
                },
                slideChange: function () {
                    swiperAnimation.init(this).animate();
                }
            },
            navigation: {
                nextEl: '.swiper-button-next-' + swiperSelector.substr(1), 
                prevEl: '.swiper-button-prev-' + swiperSelector.substr(1)
            },
            pagination: {
                el: '.swiper-pagination-' + swiperSelector.substr(1),
                clickable: true
            }
        })
        swiperControl[swiperSelector.substr(1)] = swiperSettings;
    });

});

// SELECT

import "select2";
import "select2/dist/css/select2.css";

$('.form-control > select').each(function(){
  $(this).select2({
    minimumResultsForSearch: Infinity
  }); 
});

import 'animate.css';
// import WOW from 'wow.js';

// var wow = new WOW({
//   boxClass: 'anim'
// });
// wow.init();

// import anime from 'animejs/lib/anime.es.js';